import React from 'react';
import PropTypes from 'prop-types';

import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

export default function SEO ({ pagepathname, children, description, title, image }) {
    const { site } = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    siteUrl
                    twitterHandle
                    themeName
                }
            }
        }
    `);

    return (
        <Helmet titleTemplate={`%s - ${site.siteMetadata.title}`}>
            <html lang="en-GB" className={`theme-${site.siteMetadata.themeName}`} />
            <title>{title}</title>

            <script type="text/javascript">
                {`
                /**
                 * accessibility outlines
                 * lifted mostly from:
                 * https://gist.github.com/davidgilbertson/1955dc1dde36e1fabbc6178052294560#file-unnecessarily-complicated-version-js
                 * after being found at:
                 * https://hackernoon.com/removing-that-ugly-focus-ring-and-keeping-it-too-6c8727fefcd2
                 */
                var handleFirstTab = function(e) {
                    if (e.keyCode === 9) {
                        document.body.classList.add('u-keyboard-use');
                        document.body.classList.remove('u-no-focus');
                        window.removeEventListener('keydown', handleFirstTab);
                        window.addEventListener('mousedown', handleMouseDownOnce);
                    }
                }

                var handleMouseDownOnce = function(e) {
                    document.body.classList.remove('u-keyboard-use');
                    document.body.classList.add('u-no-focus');
                    window.removeEventListener('mousedown', handleMouseDownOnce);
                    window.addEventListener('keydown', handleFirstTab);
                }

                window.addEventListener('keydown', handleFirstTab);
                `}
            </script>

            <meta content={description} name="description" />
            <meta content={site.siteMetadata.title} property="og:site_name" />
            <meta content={title} property="og:title" />
            <meta content={description} property="og:description" />
            <meta content="website" property="og:type" />
            <meta content={image || 'some-default-sharing-image.jpg'} property="og:image" />
            <meta content={`${site.siteMetadata.siteUrl}${pagepathname}`} property="og:url" />
            <meta content="summary_large_image" name="twitter:card" />
            <meta content={`@${site.siteMetadata.twitterHandle}`} name="twitter:site" />
            <meta content={`@${site.siteMetadata.twitterHandle}`} name="twitter:creator" />
            <meta content={title} name="twitter:title" />
            <meta content={description} name="twitter:description" />
            <meta content={image || 'some-default-sharing-image.jpg'} name="twitter:image:src" />
            <meta content={site.siteMetadata.siteUrl} name="twitter:domain" />
            <meta content={process.env.GATSBY_FACEBOOK_APP_ID} property="fb:app_id" />
            <meta content={process.env.GATSBY_GOOGLE_WEBMASTER_VERIFICATION_ID} name="google-site-verification" />

            <link href={`${site.siteMetadata.siteUrl}${pagepathname}`} rel="canonical" />

            <link rel="apple-touch-icon" sizes="180x180" href="/icons/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/icons/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/icons/favicon-16x16.png" />
            <link rel="manifest" href="/icons/site.webmanifest" />
            <link rel="mask-icon" href="/icons/safari-pinned-tab.svg" color="#000000" />
            <link rel="shortcut icon" href="/icons/favicon.ico" />
            <meta name="msapplication-config" content="/icons/browserconfig.xml" />

            {children}
        </Helmet>
    );
}

SEO.propTypes = {
    pagepathname: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    description: PropTypes.string,
    title: PropTypes.string,
    image: PropTypes.string,
};
